import Popovers from '../../../components/bootstrap/Popovers';
import Header, { HeaderRight } from '../../../layout/Header/Header';

const Header404 = () => {
	return (
		<Header>
			<HeaderRight>
				<Popovers title='Gulf Cars Auction'>Gulf Cars Auction</Popovers>
				<code>Gulf Cars Auction</code>
			</HeaderRight>
		</Header>
	);
};

export default Header404;
