import SetAuthToken from '../../helpers/setAuthToken';
import { apiSlice } from '../api/apiSlice';
import { userLoggedIn } from './authSlice';

export const authApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (data) => ({
				url: '/v1/auth/login',
				method: 'POST',
				body: data,
			}),

			async onQueryStarted(arg, { queryFulfilled, dispatch }) {
				try {
					const result = await queryFulfilled;

					dispatch(
						userLoggedIn({
							accessToken: result.data.access_token,
							permissions: result.data.permissions,
							user: result.data.user,
						}),
					);

					localStorage.setItem('token_admin_for_preview', result.data.access_token);

					SetAuthToken(result.data.access_token);
				} catch (err) {
					// do nothing
				}
			},
		}),
	}),
});

export const { useLoginMutation } = authApi;
