import classNames from 'classnames';
import { motion, MotionStyle } from 'framer-motion';
import { useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Tooltips from '../../components/bootstrap/Tooltips';
import ThemeContext from '../../contexts/themeContext';
import useAsideTouch from '../../hooks/useAsideTouch';
import { dashboardMenu } from '../../menu';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';
import { accountPage, allVehicle, auctions, bidAuction, buyers, misc, settings } from './menus';

interface AuthState {
	auth: any;
}

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { permissions } = useSelector((state: AuthState) => state.auth);
	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();
	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const constraintsRef = useRef(null);
	const [doc] = useState(false);

	// useEffect(() => {
	// 	if (accessToken) {
	// 		const pusher = MyPusher(accessToken);
	// 		// TODO:: pusher 403 status handle
	// 		const pusher_channel = pusher.subscribe(`private-admin.notifications`);

	// 		setChannel(pusher_channel);
	// 		setPusherInstance(pusher);
	// 	}

	// 	return () => {
	// 		if (pusherInstance && channel) {
	// 			channel.unsubscribe();
	// 			pusherInstance.disconnect();
	// 		}
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	// useEffect(() => {
	// 	if (channel && channel.bind) {
	// 		console.log(channel);
	// 		channel.bind('ADMIN_NOTIFICATION', function (data: any) {
	// 			console.log('Notification', data);

	// 			dispatch(updateNotification({ ...data }));
	// 			// dispatch(readyToBid({ ...data, auctionId }));
	// 		});
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [channel]);

	// console.log(channel);

	return (
		<>
			<motion.aside
				style={asideStyle as MotionStyle}
				className={classNames(
					'aside left-sidebar',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head' style={{ background: '#9f1b20' }}>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body' style={{ background: '#B11E24' }}>
					<Navigation menu={dashboardMenu} id='aside-dashboard' />
					<NavigationLine />
					{!doc && (
						<>
							{/* {user?.role == 1 && (
								<>
									<Navigation menu={customers(permissions)} id='customer-pages' />
									<NavigationLine />
								</>
							)} */}
							{permissions?.['member.index'] && (
								<>
									<Navigation menu={buyers(permissions)} id='customer-pages' />
									<NavigationLine />
								</>
							)}

							{/* <Navigation menu={vehiclePages(permissions)} id='aside-demo-pages' /> */}
							{permissions?.['vehicle.index'] && (
								<Navigation menu={allVehicle(permissions)} id='aside-demo-pages' />
							)}

							<NavigationLine />

							{permissions?.['auction.index'] && (
								<Navigation
									menu={auctions(permissions)}
									id='aside-auctions-pages'
								/>
							)}

							{permissions?.['bid_history.index'] && (
								<>
									<Navigation
										menu={bidAuction(permissions)}
										id='aside-bidAuction-pages'
									/>
									<NavigationLine />
								</>
							)}

							{permissions?.['vehicle.index'] && (
								<Navigation menu={misc(permissions)} id='aside-demo-misc' />
							)}

							{/* {permissions?.['catalogues.index'] && (
								<>
									<Navigation
										menu={catalogs(permissions)}
										id='aside-download-file-pages'
									/>
								</>
							)}
							{permissions?.['faq.customer_queries'] && (
								<>
									<Navigation
										menu={customer_queries(permissions)}
										id='aside-c-queries-pages'
									/>
								</>
							)}

							{permissions?.['faq.index'] && (
								<>
									<Navigation menu={faqs(permissions)} id='aside-faqs-pages' />
								</>
							)} */}

							{/* {permissions?.['accounting.index'] && (
								<>
									<Navigation
										menu={accounting(permissions)}
										id='aside-account-pages'
									/>
									<NavigationLine />
								</>
							)} */}

							{/* {user?.accounting_login_url && (
								<>
									<Navigation
										menu={accountingUrl(user?.accounting_login_url)}
										id='aside-account-pages'
									/>
									<NavigationLine />
								</>
							)} */}

							{/* {permissions?.['jobs.index'] && (
								<>
									<Navigation menu={jobs(permissions)} id='aside-jobs-pages' />
								</>
							)}
							{permissions?.['faqs.index'] && (
								<>
									<Navigation menu={faqs(permissions)} id='aside-jobs-pages' />
								</>
							)}
							{permissions?.['downloads.index'] && (
								<>
									<Navigation
										menu={downloadFile(permissions)}
										id='aside-download-file-pages'
									/>
								</>
							)}
							{permissions?.['banners.index'] && (
								<>
									<Navigation
										menu={banners(permissions)}
										id='aside-download-file-pages'
									/>
								</>
							)} */}

							<NavigationLine />

							{(permissions?.['accounting.index'] ||
								permissions?.['product-services.index']) && (
								<>
									<Navigation
										menu={accountPage(permissions)}
										id='aside-accounting-pages'
									/>
									<NavigationLine />
								</>
							)}

							{/* {user?.role == 1 && ( */}
							{(permissions?.['page.index'] ||
								permissions?.['email-notification.index'] ||
								permissions?.['faq.index'] ||
								permissions?.['model.index'] ||
								permissions?.['vehicle-damages.index'] ||
								permissions?.['vehicle-colors.index'] ||
								permissions?.['body-styles.index'] ||
								permissions?.['make.index'] ||
								permissions?.['yard.index'] ||
								permissions?.['location.index'] ||
								permissions?.['state.index'] ||
								permissions?.['country.index'] ||
								permissions?.['role.index'] ||
								permissions?.['user.index']) && (
								<Navigation menu={settings(permissions)} id='settings-page' />
							)}

							{/* )} */}

							{/* <Navigation menu={demoPages} id='aside-demo-pages' />
							<NavigationLine />
							<Navigation menu={layoutMenu} id='aside-menu' />
							<NavigationLine /> */}
						</>
					)}

					{/* {asideStatus && doc && <div className='p-4'>Documentation</div>} */}
				</div>
				<div className='aside-foot' style={{ background: '#B11E24' }}>
					{/* <nav aria-label='aside-bottom-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									setDoc(!doc);
								}}
								data-tour='documentation'>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon={doc ? 'ToggleOn' : 'ToggleOff'}
											color={doc ? 'success' : undefined}
											className='navigation-icon'
										/>
										<span className='navigation-text'>
											{t('menu:Documentation')}
										</span>
									</span>
									<span className='navigation-link-extra'>
										<Icon
											icon='Circle'
											className={classNames(
												'navigation-notification',
												'text-success',
												'animate__animated animate__heartBeat animate__infinite animate__slower',
											)}
										/>
									</span>
								</span>
							</div>
						</div>
					</nav> */}
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
