// useAutoCloseToast.js
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const useAutoCloseToast = () => {
	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.hidden) {
				toast.dismiss();
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);
};

export default useAutoCloseToast;
