import PropTypes from 'prop-types';
import { FC } from 'react';
// import LoginPageLogo from '../assets/logos/galaxy-logo.png';
import LoginPageLogo from '../assets/logos/logo.png';
import LoginPageLogo2 from '../assets/logos/logo4.png';

// import BrandLogo from '../assets/logos/galaxy.jpeg';

interface ILogoProps {
	width?: number;
	height?: number;
	loginPage?: boolean;
}
const Logo: FC<ILogoProps> = ({ width, loginPage = false }) => {
	// let logo = BrandLogo;
	let logo = LoginPageLogo;
	if (loginPage) {
		logo = LoginPageLogo2;
	}
	return <img src={logo} alt='Galaxy' width={width} />;
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	loginPage: PropTypes.bool,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
	loginPage: false,
};

export default Logo;
