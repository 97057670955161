import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	myNotifications: {
		unread_notifications: 0,
		data: {},
	},
};

const notifcationSlice = createSlice({
	name: 'notificationUser',
	initialState,
	reducers: {
		setNotification: (state, action) => {
			state.myNotifications = action.payload;
		},

		updateReadNotification: (state, action) => {
			let { unread_notifications, notification_ids } = action.payload;
			state.myNotifications.unread_notifications = unread_notifications;

			notification_ids.map((e) => {
				let cloneObj = state.myNotifications.data[e];
				if (cloneObj) {
					cloneObj.is_read = true;
					state.myNotifications.data[e] = cloneObj;
				}
			});
		},

		updateAddNotification: (state, action) => {
			let { unread_notifications, notification } = action.payload;
			state.myNotifications.unread_notifications = unread_notifications;

			let cloneObj = state.myNotifications.data;
			let keysArr = Object.keys(cloneObj);

			if (keysArr.length > 20) {
				delete cloneObj[keysArr[0]];
			}

			cloneObj[notification.id] = notification;
			state.myNotifications.data = cloneObj;
		},

		updateDeleteNotification: (state, action) => {
			console.log(action.payload);

			let { unread_notifications, notification_ids } = action.payload;
			state.myNotifications.unread_notifications = unread_notifications;

			let cloneObj = state.myNotifications.data;
			notification_ids.map((id) => {
				delete cloneObj[id];
			});
			state.myNotifications.data = cloneObj;
		},
	},
});

export const {
	setNotification,
	updateReadNotification,
	updateAddNotification,
	updateDeleteNotification,
} = notifcationSlice.actions;
export default notifcationSlice.reducer;
