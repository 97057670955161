import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	accessToken: undefined,
	permissions: undefined,
	user: undefined,
};

const authSlice = createSlice({
	name: 'authUser',
	initialState,
	reducers: {
		userLoggedIn: (state, action) => {
			state.accessToken = action.payload.accessToken;
			state.permissions = action.payload.permissions;
			state.user = action.payload.user;
		},
		userLoggedOut: (state) => {
			state.accessToken = undefined;
			state.permissions = undefined;
			state.user = undefined;
		},
	},
});

export const { userLoggedIn, userLoggedOut } = authSlice.actions;
export default authSlice.reducer;
