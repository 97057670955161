import axios from 'axios';

const base = process.env.REACT_APP_BASE_URL;

const webAxios = axios.create({
	baseURL: base,
});

const guestAxios = axios.create({
	baseURL: `${base}/api/guest`,
});

const authAxios = axios.create({
	baseURL: `${base}/api/v1`,
});

export { authAxios, guestAxios, webAxios };
