import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { apiSlice, apiSliceFile } from '../features/api/apiSlice';
import auctionSlicesReducer from '../features/auction_features/auction-slices';
import authSliceReducer from '../features/auth/authSlice';
import notificationSliceReducer from '../features/notification/notificationSlice';

//store.js
const persistConfig = {
	key: 'userAuthUser',
	storage,
};

const reducers = combineReducers({
	[apiSlice.reducerPath]: apiSlice.reducer,
	[apiSliceFile.reducerPath]: apiSliceFile.reducer,
	auth: authSliceReducer,
	auction: auctionSlicesReducer,
	notification: notificationSliceReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: (getDefaultMiddlewares) =>
		getDefaultMiddlewares({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof reducers>;
