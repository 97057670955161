const vehiclePages = (permissions) => ({
	pages: {
		id: 'pages',
		text: 'Vehicles',
		icon: 'Extension',
	},
	singlePages: {
		id: 'vehiclePages',
		text: 'Vehicle Settigs',
		path: 'vehicle-settings',
		icon: 'SettingsApplications',
		subMenu: {
			vehicleMake: {
				id: 'vehicleMake',
				text: 'Makes',
				path: 'vehicle-settings/makes?page=1&limit=20',
				icon: 'CarRepair',
				hide: !permissions?.['make.index'],
			},
			vehicleModel: {
				id: 'vehicleModel',
				text: 'Models',
				path: 'vehicle-settings/models?page=1&limit=20',
				icon: 'ElectricCar',
				hide: !permissions?.['model.index'],
			},
			vehicleBodyStyle: {
				id: 'vehicleBodyStyle',
				text: 'BodyStyle',
				path: 'vehicle-settings/body-style',
				icon: 'CarRepair',
				hide: !permissions?.['body-styles.index'],
			},
		},
	},
});

const vehicles = (permissios) => ({
	singlePages: {
		id: 'vehiclePage',
		text: 'Vehicles',
		path: 'vehicles?page=1&limit=20',
		icon: 'ElectricCar',
		subMenu: null,
		hide: !permissios?.['vehicle.index'],
	},
});

const allVehicle = (permissions) => ({
	pages: {
		id: 'pages',
		text: 'Vehicles',
		icon: 'ElectricCar',
		hide: !permissions?.['vehicle.index'],
	},
	singlePages: {
		id: 'vehiclePage',
		text: 'Vehicles',
		path: 'vehicles',
		icon: 'ElectricCar',
		hide: !permissions?.['vehicle.index'],

		subMenu: {
			inventory: {
				id: 'inventory',
				text: 'On Hand',
				path: 'inventory-vehicles?page=1&limit=20&statuses=1',
				icon: 'ElectricCar',
				hide: !permissions?.['vehicle.index'],
			},

			on_approval: {
				id: 'on_approval',
				text: 'On Approval',
				path: 'on-approval-vehicles?page=1&limit=20&statuses=0',
				icon: 'ElectricCar',
				hide: !permissions?.['vehicle.approve_reject'],
			},

			documents_validation_pending: {
				id: 'documents_validation_pending',
				text: 'Doc Validation Pending',
				path: 'documents-validation-pending-vehicles?page=1&limit=20&statuses=25',
				icon: 'ElectricCar',
				hide: !permissions?.['vehicle.index'],
			},

			auction_vehicle: {
				id: 'auction_vehicle',
				text: 'Auction Vehicle',
				path: 'auction-vehicles?page=1&limit=20&statuses=5,10',
				icon: 'ElectricCar',
				hide: !permissions?.['vehicle.index'],
			},

			sold_vehicle: {
				id: 'sold_vehicle',
				text: 'Sold Vehicle',
				path: 'sold-vehicles?page=1&limit=20&statuses=15,20',
				icon: 'ElectricCar',
				hide: !permissions?.['vehicle.index'],
			},

			// relist: {
			// 	id: 'relist_vehicle',
			// 	text: 'Pending To Relist',
			// 	path: 'relist-vehicles?page=1&limit=20&statuses=3',
			// 	icon: 'ElectricCar',
			// 	hide: !permissions?.['vehicle.index'],
			// },

			selling_on_approval: {
				id: 'selling_on_approval_vehicle',
				text: 'Selling On Approval',
				path: 'selling-on-approval-vehicles?page=1&limit=20',
				icon: 'ElectricCar',
				hide: !permissions?.['vehicle.index'],
			},

			returned_vehicle: {
				id: 'returned_vehicle',
				text: 'Return Vehicle',
				path: 'returned-vehicles?page=1&limit=20',
				icon: 'ElectricCar',
				hide: !permissions?.['vehicle.index'],
			},

			all_vehicle: {
				id: 'all_vehicle',
				text: 'Inventory',
				path: 'vehicles?page=1&limit=20',
				icon: 'ElectricCar',
				hide: !permissions?.['vehicle.index'],
			},
		},
	},
});

const misc = (permissions) => ({
	pages: {
		id: 'pages',
		text: 'Misc.',
		icon: 'MiscellaneousServices',
		hide: !permissions?.['vehicle.index'],
	},
	singlePages: {
		id: 'misc',
		text: 'Misc.',
		path: 'misc',
		icon: 'MiscellaneousServices',
		// hide: !permissions?.['vehicle.index'],

		subMenu: {
			catalogues: {
				id: 'catalogs',
				text: 'Catalogs',
				path: 'settings/catalogs?page=1&limit=20',
				icon: 'DriveFileMove',
				hide: !permissions?.['catalogues.index'],
			},

			customer_queries: {
				id: 'customer_queries_page',
				text: 'Customer Queries',
				path: 'customer-queries?page=1&limit=20',
				icon: 'Quickreply',
				subMenu: null,
				hide: !permissions?.['faq.customer_queries'],
			},

			faqs: {
				id: 'faq-page',
				text: 'FAQ',
				path: 'faqs?page=1&limit=20',
				icon: 'Quickreply',
				subMenu: null,
				hide: !permissions?.['faq.index'],
			},

			jobs: {
				id: 'jobs',
				text: 'Jobs',
				path: 'jobs?page=1&limit=20',
				icon: 'AddTask',
				subMenu: null,
				hide: !permissions?.['jobs.index'],
			},

			downloadFile: {
				id: 'downloadFile',
				text: 'Download Files',
				path: 'settings/download-files?page=1&limit=20',
				icon: 'DriveFileMove',
				hide: !permissions?.['downloads.index'],
			},

			banners: {
				id: 'banners',
				text: 'Banners',
				path: 'settings/banners?page=1&limit=20',
				icon: 'Image',
				hide: !permissions?.['banners.index'],
			},
		},
	},
});

const accountingPage = (permissions) => ({
	pages: {
		id: 'accounting-page',
		text: 'Accounting',
		icon: 'Calculate',
	},
	singlePages: {
		id: 'invoiePage',
		text: 'Invoice',
		icon: 'Calculate',
		hide: !permissions?.['accounting.index'],
		subMenu: {
			invoice: {
				id: 'invoicePage',
				text: 'Invoice',
				path: 'invoices?page=1&limit=20',
				icon: 'Calculate',
				subMenu: null,
			},
			service: {
				id: 'servicePage',
				text: 'Product & Service',
				path: 'product-service?page=1&limit=20',
				icon: 'People',
				subMenu: null,
			},
		},
	},
});

const settings = (permissions) => ({
	pages: {
		id: 'pages',
		text: 'Settings',
		icon: 'Extension',
	},
	singlePages: {
		id: 'settingsPage',
		text: 'Settings',
		path: 'settings',
		icon: 'SettingsApplications',
		subMenu: {
			systemSettings: {
				id: 'system_settings',
				text: 'System Settings',
				path: 'settings/system-settings',
				icon: 'AppSettingsAlt',
				hide: !permissions?.['user.index'],
			},

			user: {
				id: 'users',
				text: 'Users',
				path: 'settings/users?page=1&limit=20',
				icon: 'AccountCircle',
				hide: !permissions?.['user.index'],
			},

			role: {
				id: 'roles',
				text: 'Roles',
				path: 'settings/roles',
				icon: 'Lock',
				hide: !permissions?.['role.index'],
			},
			// zones: {
			// 	id: 'zones',
			// 	text: 'Zones',
			// 	path: 'settings/zones?page=1&limit=20',
			// 	icon: 'AcUnit',
			// 	hide: !permissions?.['zone.index'],
			// },
			countries: {
				id: 'countries',
				text: 'Countries',
				path: 'settings/countries?page=1&limit=20',
				icon: 'LocationCity',
				hide: !permissions?.['country.index'],
			},
			states: {
				id: 'states',
				text: 'States',
				path: 'settings/states?page=1&limit=20',
				icon: 'AccountBalance',
				hide: !permissions?.['state.index'],
			},
			locations: {
				id: 'locations',
				text: 'Locations',
				path: 'settings/locations?page=1&limit=20',
				icon: 'Map',
				hide: !permissions?.['location.index'],
			},
			acutionYard: {
				id: 'acutionYard',
				text: 'Acution Yard',
				path: 'settings/acution-yard?page=1&limit=20',
				icon: 'CarRental',
				hide: !permissions?.['yard.index'],
			},
			vehicleMake: {
				id: 'vehicleMake',
				text: 'Makes',
				path: 'vehicle-settings/makes?page=1&limit=20',
				icon: 'CarRepair',
				hide: !permissions?.['make.index'],
			},
			vehicleBodyStyle: {
				id: 'vehicleBodyStyle',
				text: 'Body Style',
				path: 'vehicle-settings/body-style?page=1&limit=20',
				icon: 'CarRepair',
				hide: !permissions?.['body-styles.index'],
			},
			vehicleColorStyle: {
				id: 'vehicleColorStyle',
				text: 'Color Style',
				path: 'vehicle-settings/color-style?page=1&limit=20',
				icon: 'CarRepair',
				hide: !permissions?.['vehicle-colors.index'],
			},
			vehicleDamage: {
				id: 'vehicleDamage',
				text: 'Vehicle Damage',
				path: 'vehicle-settings/vehicle-damage?page=1&limit=20',
				icon: 'CarRepair',
				hide: !permissions?.['vehicle-damages.index'],
			},
			vehicleModel: {
				id: 'vehicleModel',
				text: 'Models',
				path: 'vehicle-settings/models?page=1&limit=20',
				icon: 'ElectricCar',
				hide: !permissions?.['model.index'],
			},

			email_template: {
				id: 'email-notifications',
				text: 'Email Notifications',
				path: 'settings/email-notifications?page=1&limit=20',
				icon: 'Email',
				hide: !permissions?.['email-notification.index'],
			},
			pages: {
				id: 'pages',
				text: 'Pages',
				path: 'settings/pages?page=1&limit=20',
				icon: 'Pages',
				hide: !permissions?.['page.index'],
			},
		},
	},
});

const accountPage = (permissions) => ({
	pages: {
		id: 'pages',
		text: 'Accounting',
		icon: 'Calculate',
	},
	singlePages: {
		id: 'accountPAge',
		text: 'Accounting',
		path: 'accounting',
		icon: 'Calculate',
		subMenu: {
			service: {
				id: 'servicePage',
				text: 'Product & Service',
				path: 'product-service?page=1&limit=20',
				icon: 'People',
				subMenu: null,
				hide: !permissions?.['product-services.index'],
			},
			invoice: {
				id: 'invoicePage',
				text: 'Invoice',
				path: 'invoices?page=1&limit=20',
				icon: 'Calculate',
				subMenu: null,
				hide: !permissions?.['accounting.index'],
			},

			bill: {
				id: 'billPage',
				text: 'Bill',
				path: 'bills?page=1&limit=20',
				icon: 'Calculate',
				subMenu: null,
				hide: !permissions?.['accounting.index'],
			},
		},
	},
});

const customers = (permissions) => ({
	pages: {
		id: 'customer-pages',
		text: 'Customers',
		icon: 'Extension',
	},
	singlePages: {
		id: 'customerPages',
		text: 'Customer',
		path: 'customer-settings',
		icon: 'ManageAccounts',
		subMenu: {
			seller: {
				id: 'sellerPage',
				text: 'Sellers',
				path: 'customer-settings/sellers?page=1&limit=20',
				icon: 'People',
				subMenu: null,
				hide: !permissions?.['seller.index'],
			},
			member: {
				id: 'memberPage',
				text: 'Customers',
				path: 'customer-settings/members?page=1&limit=20',
				icon: 'People',
				subMenu: null,
				hide: !permissions?.['member.index'],
			},
			// newMember: {
			// 	id: 'newMemberPage',
			// 	text: 'New Members',
			// 	path: 'customer-settings/new-members?page=1&limit=20',
			// 	icon: 'People',
			// 	subMenu: null,
			// 	hide: !permissions?.['member.index'],
			// },
		},
	},
});

const sellers = (permissions) => ({
	singlePages: {
		id: 'sellerPage',
		text: 'Sellers',
		path: 'customer-settings/sellers?page=1&limit=20',
		icon: 'People',
		subMenu: null,
		hide: !permissions?.['seller.index'],
	},
});

const buyers = (permissions) => ({
	singlePages: {
		id: 'memberPage',
		text: 'Customers',
		path: 'customer-settings/members?page=1&limit=20',
		icon: 'People',
		subMenu: null,
		hide: !permissions?.['member.index'],
	},
});

// const faqs = (permissions) => ({
// 	singlePages: {
// 		id: 'faqsPage',
// 		text: 'FAQ',
// 		path: 'faqs?page=1&limit=20',
// 		icon: 'Quickreply',
// 		subMenu: null,
// 		hide: !permissions?.['faq.index'],
// 	},
// });

const customer_queries = (permissions) => ({
	singlePages: {
		id: 'customer_queries_page',
		text: 'Customer Queries',
		path: 'customer-queries?page=1&limit=20',
		icon: 'Quickreply',
		subMenu: null,
		hide: !permissions?.['faq.customer_queries'],
	},
});

// const accounting = (permissions) => ({
// 	singlePages: {
// 		id: 'accountingPage',
// 		text: 'Accounting',
// 		path: '/accounting',
// 		icon: 'Calculate',
// 		subMenu: null,
// 		hide: !permissions?.['accounting.index'],
// 	},
// });

const accountingUrl = (url) => ({
	singlePages: {
		id: 'accountingPage',
		text: 'Accounting',
		path: url,
		icon: 'Calculate',
		subMenu: null,
		hide: url ? false : true,
	},
});

const auctions = (permissions) => ({
	singlePages: {
		id: 'auctions',
		text: 'Auctions',
		path: 'auctions?page=1&limit=20',
		icon: 'Bullhorn',
		subMenu: null,
		hide: !permissions?.['auction.index'],
	},
});

const bidAuction = (permissions) => ({
	singlePages: {
		id: 'bidAuction',
		text: 'Auction Bids',
		path: 'auction-bids?page=1&limit=20',
		icon: 'Construction',
		subMenu: null,
		hide: !permissions?.['bid_history.index'],
	},
});

const jobs = (permissions) => ({
	singlePages: {
		id: 'jobs',
		text: 'Jobs',
		path: 'jobs?page=1&limit=20',
		icon: 'AddTask',
		subMenu: null,
		hide: !permissions?.['jobs.index'],
	},
});

const faqs = (permissions) => ({
	singlePages: {
		id: 'faq-page',
		text: 'FAQ',
		path: 'faqs?page=1&limit=20',
		icon: 'Quickreply',
		subMenu: null,
		hide: !permissions?.['faq.index'],
	},
});

const downloadFile = (permissions) => ({
	singlePages: {
		id: 'downloadFile',
		text: 'Download Files',
		path: 'settings/download-files?page=1&limit=20',
		icon: 'DriveFileMove',
		hide: !permissions?.['downloads.index'],
	},
});

const catalogs = (permissions) => ({
	singlePages: {
		id: 'catalogs',
		text: 'Catalogs',
		path: 'settings/catalogs?page=1&limit=20',
		icon: 'DriveFileMove',
		hide: !permissions?.['catalogues.index'],
	},
});

const banners = (permissions) => ({
	singlePages: {
		id: 'banners',
		text: 'Banners',
		path: 'settings/banners?page=1&limit=20',
		icon: 'Image',
		hide: !permissions?.['banners.index'],
	},
});

const profiles = () => ({
	singlePages: {
		id: 'profilePage',
		text: 'Profiles',
		path: 'profiles',
		icon: 'Quickreply',
		subMenu: null,
		// hide: !permissions?.['faq.index'],
	},
});

export {
	accountPage,
	accountingPage,
	accountingUrl,
	allVehicle,
	auctions,
	banners,
	bidAuction,
	buyers,
	catalogs,
	customer_queries,
	customers,
	downloadFile,
	faqs,
	jobs,
	misc,
	profiles,
	sellers,
	settings,
	vehiclePages,
	vehicles
};

